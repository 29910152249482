<template>
  <div>
    <my-header title="历史反馈" class="myheader"> 
      <div slot="right">
        
      </div>
    </my-header>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :offset="5"
        @load="getList"
      >
        <div v-for="(item, index) in list" :key="index" class="list-item">
          <p class="list-item-title">{{ item.createTime }}</p>
          <p class="list-item-content">
            {{ item.feedCon }}
            <!-- 我登录消供学园的账号时，是通过手机验证码登录的。明明
            只点击了一次“发送验证码”，但是经常会收到两个验证码
            的短信，而且两个验证码短信收到的时间间隔较大，导致输
            入验证码时经常会不成功。
            我登录消供学园的账号时，是通过手机验证码登录的。明明
            只点击了一次“发送验证码”，但是经常会收到两个验证码
            的短信，而且两个验证码短信收到的时间间隔较大，导致输
            入验证码时经常会不成功。 -->
          </p>
          <img
            v-for="i in item.feedbackFiles"
            :key="i.id"
            :src="i.fileUrl"
            class="list-item-img"
            @click="reviewImg(item)"
          />
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getHistoryList } from '@/api/feedBack'
import { ImagePreview } from 'vant'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      pageNo: 1,
      pageSize: 10,
    }
  },
  created() {},
  computed: {},
  methods: {
    getList() {
      getHistoryList({ pageNo: this.pageNo, pageSize: this.pageSize }).then(
        (res) => {
          console.log(res.result)
          this.list.push(...res.result.records)
          this.pageNo += 1
          if (this.list.length == res.result.total) {
            this.finished = true
          }
          this.loading = false
        }
      )
    },
    reviewImg(query) {
      let arr = []
      for (const item of query.feedbackFiles) {
        arr.push(item.fileUrl)
      }
      ImagePreview({
        images: arr,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.list {
  padding: 64px 64px 209px 64px;
  height: 2350px;
  overflow-y: scroll;
  &-item {
    width: 100%;
    min-height: 586px;
    box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
      -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
    border-radius: 32px;
    margin-bottom: 40px;
    padding: 48px;
    &-title {
      font-size: 54px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 34px;
    }
    &-content {
      font-size: 40px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 40px;
    }
    &-img {
      // width: 160px;
      height: 160px;
      margin-right: 48px;
    }
  }
}
</style>
