import request from '@/utils/request'

// 添加反馈
export function addFeedBack(query) {
  return request({
    url: '/knowledge/feedback/client/add',
    method: 'post',
    params: query
  })
}

// 查询历史反馈记录
export function getHistoryList(query) {
  return request({
    url: '/knowledge/feedback/client/history',
    method: 'get',
    params: query
  })
}